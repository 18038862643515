import { PostAuthComponentParams } from '../../types/auth';
import { useEffect, useState } from 'react';
import {
  Button,
  Container,
  ContentLayout,
  Form,
  Header,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import signInSwitchNeededMessages from '../../i18n/signInSwitchNeeded.messages';
import { useAmplifyConfig } from '../../hooks/useAmplifyConfig';
import { replayAuth } from '../../services/auth';
import { emitHomeComingRUMEvents } from '../../services/emitHomeComingRUMEvents';

const SignInSwitchNeeded = ({
  user,
  onContinue,
  onCancel,
  config,
}: PostAuthComponentParams) => {
  const [signInSwitchFlowEnabled, setEnableSignInSwitch] =
    useState<boolean>(false);

  const { formatMessage } = useIntl();

  useAmplifyConfig(config);

  const idpToUse = user.signin_switch_needed;

  useEffect(() => {
    if (config.enableSignInSwitch === 'true' && idpToUse) {
      setEnableSignInSwitch(true);
      emitHomeComingRUMEvents(user, 'sign_in_switch_needed');
    } else {
      onContinue();
    }
  }, [config.enableSignInSwitch, idpToUse, onContinue, user]);

  function handleContinueToIdp() {
    if (idpToUse) {
      replayAuth(idpToUse, new URLSearchParams(window.location.search));
    } else {
      throw new Error(`Idp recommended is not valid`);
    }
  }

  if (!signInSwitchFlowEnabled) {
    return <></>;
  }
  return (
    <ContentLayout
      header={
        <Header variant={'h2'}>
          {formatMessage(signInSwitchNeededMessages.header, {
            orgName:
              user.organization?.name ??
              formatMessage(signInSwitchNeededMessages.organization),
          })}
        </Header>
      }
    >
      <Container
        header={
          <Header variant={'h3'}>
            {formatMessage(signInSwitchNeededMessages.containerTitle)}
          </Header>
        }
      >
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  onCancel();
                }}
                data-testid={'return-to-signin'}
              >
                {formatMessage(
                  signInSwitchNeededMessages.returnToSignInPageButton
                )}
              </Button>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  handleContinueToIdp();
                }}
                data-testid={'continue-to-signin'}
                variant={'primary'}
              >
                {formatMessage(signInSwitchNeededMessages.continueButton, {
                  idpToUse: idpToUse,
                })}
              </Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween direction="vertical" size="xs">
            <TextContent>
              {formatMessage(signInSwitchNeededMessages.containerBody, {
                idpToUse: idpToUse,
              })}
            </TextContent>
          </SpaceBetween>
        </Form>
      </Container>
    </ContentLayout>
  );
};

export default SignInSwitchNeeded;
