/* eslint-disable no-console */
import { useEffect } from 'react';
import { LiveComponent } from '@amzn/aws-tc-xb-xps';
import { PostAuthComponentParams } from '../../types/auth';
import { AppConfig } from '../../types/app';
import { useApolloClient } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useIntl } from 'react-intl';
import { emitHomeComingRUMEvents } from '../../services/emitHomeComingRUMEvents';

export const getAuthLink = (jwtToken: string) => {
  return setContext((_, { headers }) => {
    console.log('Setting auth header');
    console.log(headers);
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${jwtToken}`,
      },
    };
  });
};

function ProfileAndPreferences({
  config,
  onContinue,
  user,
}: PostAuthComponentParams) {
  const apolloClient = useApolloClient();
  // get current language or locale from useIntl hook
  const { locale } = useIntl();

  useEffect(() => {
    document.addEventListener('edit_profile_all_sections_success', (e) => {
      onContinue();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      config.enableProfilePreferences !== 'true' ||
      user.profile_completion_required !== 'true' ||
      !shouldRenderProfileAndPreferencesForClient(config)
    ) {
      onContinue();
      return;
    }
    emitHomeComingRUMEvents(user, 'starting_profile_preferences_flow');
  }, [config, onContinue, user, user.profile_completion_required]);

  if (config.enableProfilePreferences === 'true') {
    console.log('Setting link');
    console.log(apolloClient);
    console.log(apolloClient.link);
    apolloClient.setLink(getAuthLink(user.jwtToken!).concat(apolloClient.link));
    console.log('Link set');
    console.log(apolloClient.link);
  }

  console.log('current locale is,', locale);
  return (
    <LiveComponent
      experienceProviderName="userorg"
      componentName="UserProfileEditAll"
      payload={JSON.stringify({
        token: user?.jwtToken,
        language: locale,
      })}
    />
  );
}

const shouldRenderProfileAndPreferencesForClient = (
  config: AppConfig
): boolean => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const client = urlSearchParams.get('client_id');

  if (
    config.userProfileRequiredForClients === undefined ||
    config.userProfileRequiredForClients.length === 0
  )
    return false;

  return config.userProfileRequiredForClients.includes(client!);
};

export default ProfileAndPreferences;
