import { GandalfUser, PostAuthComponentParams } from '../../types/auth';
import { useEffect } from 'react';
import { AppConfig } from '../../types/app';
import { redirectToLXPAuth } from '../../services/auth';
import providersService from '../../services/providers';
import {
  IdPs,
  isEltiProvider,
  isStandardProvider,
} from '../../constants/providers';
import { emitHomeComingRUMEvents } from '../../services/emitHomeComingRUMEvents';

const SkipPostAuthElti = ({
  user,
  onContinue,
  onCancel,
  config,
  idpDetails,
}: PostAuthComponentParams): JSX.Element => {
  useEffect(() => {
    if (config.enableSkipPostAuthELTI !== 'true') {
      onContinue();
      return;
    }
    const checkIsELtiIdp = async (user: GandalfUser, config: AppConfig) => {
      if (idpDetails && isEltiProvider(idpDetails.idpType)) {
        emitHomeComingRUMEvents(user, `skip_post-auth_flows_for_elti`);
        redirectToLXPAuth(
          config.enableGandalfSession === 'true' &&
            config.gandalfSessionEndpoint &&
            !isStandardProvider(user.providerName)
            ? providersService.transformNameToIdProvider(
                IdPs.GandalfSession,
                config.gandalfDomain
              )
            : providersService.transformNameToIdProvider(
                user.providerName,
                config.gandalfDomain
              )
        );
      } else {
        onContinue();
      }
    };
    checkIsELtiIdp(user, config);
    // eslint-disable-next-line
  }, []);

  return <></>;
};

export default SkipPostAuthElti;
