import { PostAuthComponentParams } from '../../types/auth';
import {
  Alert,
  Button,
  Container,
  ContentLayout,
  Form,
  Header,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import profileDeactivatedMessages from '../../i18n/profileDeactivated.messages';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { emitHomeComingRUMEvents } from '../../services/emitHomeComingRUMEvents';

const ProfileDeactivated = ({
  user,
  config,
  onContinue,
  onCancel,
}: PostAuthComponentParams): JSX.Element => {
  const { formatMessage } = useIntl();
  useEffect(() => {
    if (
      user?.deactivated === true &&
      config.enableShowProfileDeactivatedScreen === 'true'
    ) {
      emitHomeComingRUMEvents(user, `user_deactivated`);
    }
  }, [config.enableShowProfileDeactivatedScreen, user]);
  if (config.enableShowProfileDeactivatedScreen !== 'true') {
    onContinue();
  }
  if (user.deactivated !== true) {
    onContinue();
    return <></>;
  }
  return (
    <ContentLayout>
      <Container
        header={
          <Header variant={'h2'}>
            {formatMessage(profileDeactivatedMessages.containerTitle)}
          </Header>
        }
      >
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  onCancel();
                }}
                data-testid={'return-to-signin'}
                variant={'primary'}
              >
                {formatMessage(profileDeactivatedMessages.returnToSignInButton)}
              </Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween direction="vertical" size="xs">
            <TextContent>
              {formatMessage(profileDeactivatedMessages.description)}
            </TextContent>
            <Alert type={'info'}>
              {formatMessage(
                profileDeactivatedMessages.personalBuilderIdRecommendation
              )}
            </Alert>
          </SpaceBetween>
        </Form>
      </Container>
    </ContentLayout>
  );
};

export default ProfileDeactivated;
