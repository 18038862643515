import { useEffect, useState } from 'react';

import { useNonceGenerator } from '../../contexts/NonceGeneratorContextProvider';
import { PostAuthComponentParams } from '../../types/auth';
import { REQUIRE_EMAIL_VERIFICATION } from '../../constants/auth';
import useVibeAttributes from '../../hooks/useVibeAttributes';
import startEmailVerification from '../../services/startEmailVerification';
import { emitHomeComingRUMEvents } from '../../services/emitHomeComingRUMEvents';

export const VERIFY_EMAIL_COOKIE_NAME = 'VerifyEmail';
export const VERIFY_EMAIL_COOKIE_EXPIRATION_MS = 1 * 60 * 1000; // 1 minute

const VerifyEmail = ({
  user,
  onContinue,
  onCancel,
  config,
}: PostAuthComponentParams) => {
  const [isLoading, setIsLoading] = useState(true);
  const nonceGenerator = useNonceGenerator();
  const params = new URLSearchParams(window.location.search);

  const { needsVerification, error } = useVibeAttributes(config, user, params);

  if (error !== undefined) {
    // eslint-disable-next-line no-console
    console.error(`Error fetching attributes: ${error}`);
    throw new Error('Failed getting Vibe Attributes');
  }

  const isRequiredToEmailVerifyFromToken =
    user.email_verification_required === true;

  const isRequestToEmailVerifyFromUrl =
    params.get(REQUIRE_EMAIL_VERIFICATION) === 'true';

  useEffect(() => {
    if (config.enableEmailVerification !== 'true') return onContinue();
    if (
      !isRequestToEmailVerifyFromUrl &&
      needsVerification === false &&
      (!isRequiredToEmailVerifyFromToken || user.email_verified)
    ) {
      onContinue();
    } else if (!user.jwtToken) {
      // eslint-disable-next-line no-console
      console.debug('User is missing a JWT token, aborting the sign-in');
      throw new Error('Token not found');
    } else if (!user.email) {
      // eslint-disable-next-line no-console
      console.debug('User has no email, nothing to verify');
      // Note: it is up to each LXP to display an appropriate error message here if needed
      onContinue();
    } else if (
      params.get(REQUIRE_EMAIL_VERIFICATION) === 'true' ||
      (needsVerification !== undefined && needsVerification === true) ||
      (user.email_verification_required === true && !user.email_verified)
    ) {
      emitHomeComingRUMEvents(user, 'starting_email_verification_flow');
      //Start Email Verification flow
      startEmailVerification(nonceGenerator, params, user, config);
    }
    // eslint-disable-next-line
  }, [
    config,
    nonceGenerator,
    onContinue,
    user,
    needsVerification,
    isRequestToEmailVerifyFromUrl,
    isRequiredToEmailVerifyFromToken,
  ]);

  useEffect(() => {
    if (needsVerification !== undefined) {
      setIsLoading(false);
    }
  }, [needsVerification]);

  return isLoading ? null : <></>;
};

export default VerifyEmail;
